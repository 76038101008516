import { admin } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentServiceAttach`
}

//= > 創建服務
export const CreateAttachService = async ({
  shopId,
  name,
  isPublic,
  price,
  showPrice,
  showTime,
  bookingTime,
  order,
  appointmentServices,
}) => {
  const res = await admin({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      isPublic,
      price,
      showPrice,
      showTime,
      bookingTime,
      order,
      appointmentServices,
    },
  })

  return res.data
}

//= > 取得服務
export const GetAttachService = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得服務總數
export const GetAttachServiceCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res
}

//= > 取得指定服務
export const FindAttachService = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新服務
export const UpdateAttachService = async ({
  shopId,
  id,
  name,
  isPublic,
  price,
  showPrice,
  showTime,
  bookingTime,
  order,
  appointmentServices,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      isPublic,
      price,
      showPrice,
      showTime,
      bookingTime,
      order,
      appointmentServices,
    },
  })

  return res.data
}

//= > 刪除服務
export const DeleteAttachService = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}
