<template>
  <el-select
    v-model="syncModel"
    :multiple="multiple"
    :placeholder="showAll? '請選擇附加服務': '搜尋附加服務'"
    no-data-text="暫無數據"
    value-key="id"
    collapse-tags
    :disabled="disabled"
    @change="$emit('change', attachServiceList)"
  >
    <el-option
      v-for="item in filterAvailables(attachServiceList)"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </el-select>
</template>

<script>
import { FindService } from '@/api/service'
import { GetAttachServiceCount, GetAttachService } from '@/api/attachService'
import { mapGetters } from 'vuex'
import { filter } from 'lodash'

export default {
  name: 'AttachServiceSelect',
  props: ['model', 'multiple', 'services', 'showAll', 'disabled'],

  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  data: () => ({
    attachServiceList: [],
  }),

  watch: {
    async services () {
      console.log('services change', this.syncModel)
      await this.findService()
      if (this.syncModel === '' || !this.syncModel) return
      this.syncModel.forEach((item, index) => {
        if (!this.attachServiceList.find(el => el.id === item.id)) {
          this.syncModel.splice(index, 1)
        }
      })
    },
  },

  async mounted () {
    if (this.showAll) {
      await this.getAllAttachService()
      return
    }
    await this.findService()
  },

  methods: {
    filterAvailables (data) {
      return filter(data, (i) => !i.isRemove) || []
    },
    async findService () {
      if (!this.services) {
        console.log('no services')
        this.attachServiceList = []
        return
      }
      // FIXME 只移除重複的
      this.attachServiceList = []

      if (!(this.services && this.services.id)) return
      const [res, err] = await FindService({
        shopId: this.shop,
        id: this.services.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }

      this.attachServiceList = res.AppointmentServiceAttaches
    },

    async getAllAttachService () {
      try {
        let max = await this.getAttachServiceCount()
        max = max.data.count
        const options = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }
        const res = await GetAttachService(options)
        this.attachServiceList = res
        while (this.attachServiceList.length < max) {
          options.start += options.limit
          const res = await GetAttachService(options)
          this.attachServiceList.push(...res)
          console.log('attachServiceList', this.attachServiceList.length)
        }
      } catch (error) {
        console.log(error)
      }
    },

    async getAttachServiceCount () {
      try {
        return await GetAttachServiceCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
